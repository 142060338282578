<template>
  <div class="inscription-confirmations" v-if="hasAllPerms(['portal.view_', 'youth.view_seanceinscription'])">
    <page-header
      title="Liste des inscriptions acceptées ou refusées"
      icon="fa fa-history"
      :links="getLinks()"
    >
    </page-header>
    <div class="sub-header" v-show="initialLoadingDone">
      <b-row>
        <b-col cols="8">
          <h2>
            <counter-label :counter="inscriptionsCount" label="inscription"></counter-label>
          </h2>
        </b-col>
        <b-col cols="4" class="text-right">
          <pagination
            :pages-count="pagesCount"
            :has-next="hasNext"
            :has-previous="hasPrevious"
            @change="onPageChanged($event)"
            style="display: inline-block"
          >
          </pagination>
          <b-checkbox
            :checked="noPagination"
            :disabled="noPaginationDisabled"
            @change="reloadPagination"
            style="margin-bottom: 5px; font-size: 16px; margin-top: -10px;"
          >
            Tout voir
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <div class="filter-bar">
            <b-row>
              <b-col cols="9">
                <div class="filter-bar-title">Filtrer les inscriptions</div>
              </b-col>
              <b-col cols="3">
                <b-form-checkbox
                  id="onlyPortal" v-model="onlyPortal" v-if="services.length"
                > Seulement du portail
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group
                  id="date-select-group"
                  label="Date"
                  label-for="dateFilter"
                  label-cols-sm="4"
                  description="Date d'acceptation ou de refus"
                >
                  <b-form-input
                    type="date"
                    v-model="dateFilter"
                    id="dateFilter"
                    @change="onActionDateChanged($event)"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  id="date-select-group"
                  label="Inscription"
                  label-for="insDateFilter"
                  label-cols-sm="4"
                  description="Date d'inscription"
                >
                  <b-form-input
                    type="date"
                    v-model="insDateFilter"
                    id="insDateFilter"
                    @change="onActionDateChanged($event)"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <div v-if="onlyPortal">
                  <youth-homes-portal-selector
                    :initial-ids="initialYouthHomes"
                    @change="onYouthHomesChanged($event.youthHomes, $event.initial)"
                  ></youth-homes-portal-selector>
                </div>
                <div v-else>
                  <youth-homes-multiple-selector
                    :initial-ids="initialYouthHomes"
                    @change="onYouthHomesChanged($event.youthHomes, $event.initial)"
                  ></youth-homes-multiple-selector>
                </div>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  id="period-select-group"
                  label="Période"
                  label-for="period"
                >
                  <seance-periods-selector
                    :initial-id="periodInitialId"
                    @change="onPeriodChanged($event.period)"
                    @init="periodFilter=$event.period"
                  >
                  </seance-periods-selector>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col>
          <div class="filter-bar-title">Plus de filtres</div>
          <b-row>
            <b-col>
              <b-form-group
                id="seance-date-select-group"
                label="Date de séance"
                label-for="dateFilter"
                label-cols-sm="3"
              >
                <b-form-input
                  type="date"
                  v-model="seanceDateFilter"
                  id="seanceDateFilter"
                  @change="onSeanceDateChanged($event)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="member-filter-group"
                label="Famille"
                label-for="familyFilter"
                label-cols-sm="3"
              >
                <family-typeahead
                  :initial-id="familyInitialId"
                  @change="onFamilyChanged($event.family)"
                  @init="familyFilter=$event.family"
                >
                </family-typeahead>
              </b-form-group>
              <b-form-group
                id="member-filter-group"
                label="Enfant"
                label-for="individualFilter"
                label-cols-sm="3"
              >
                <individual-typeahead
                  :initial-id="individualInitialId"
                  @change="onIndividualChanged($event.individual)"
                  @init="individualFilter=$event.individual"
                >
                </individual-typeahead>
              </b-form-group>
              <b-form-group
                id="status-filter-group"
                label="Statut"
                label-for="statusFilter"
                label-cols-sm="3"
              >
                <b-select id="statusFilter" v-model="statusFilter" @change="onStatusChanged()">
                  <b-select-option :value="choice" v-for="choice of statusChoices" :key="choice.id">
                    {{ choice.name }}
                  </b-select-option>
                </b-select>
              </b-form-group>
              <b-checkbox
                v-model="detailDisplay"
              >
                <b>Affichage complet</b>
              </b-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row v-if="!isLoading(loadingName)">
      <b-col>
        <div ref="printMe">
          <ul class="filter-info">
            <li>{{ youthHomesFilterAsText }}</li>
            <li v-if="periodFilter">{{ periodFilter.name }}</li>
            <li v-if="dateFilter">accepté ou réfusé le {{ dateFilter | dateToString('dddd LL') }}</li>
            <li v-if="insDateFilter">inscrit le {{ insDateFilter | dateToString('dddd LL') }}</li>
            <li v-if="seanceDateFilter">{{ seanceDateFilter | dateToString('dddd LL') }}</li>
            <li v-if="individualFilter">{{ individualFilter.firstAndLastName() }}</li>
            <li v-if="familyFilter">Famille {{ familyFilter.entity.name }}</li>
            <li v-if="statusFilter && statusFilter.id">{{ statusFilter.name }}</li>
          </ul>
          <div v-if="inscriptions.length === 0" class="empty-text">
            Aucune inscription pour les critères sélectionnés.
          </div>
          <div ref="excelTable">
            <div class="small-text" v-if="!isLoading(loadingName) && displayedInscriptions.length">
              <x-table
                :columns="columns"
                :items="items"
              ></x-table>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import IndividualTypeahead from '@/components/Individual/IndividualTypeahead'
import FamilyTypeahead from '@/components/Entity/FamilyTypeahead'
import PageHeader from '@/components/Layout/PageHeader'
import SeancePeriodsSelector from '@/components/Seances/SeancePeriodsSelector'
import YouthHomesMultipleSelector from '@/components/Seances/YouthHomesMultipleSelector'
import YouthHomesPortalSelector from '@/components/Seances/YouthHomesPortalSelector'
import { makeChoice } from '@/types/base'
import { makeFamily } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { makePortalService } from '@/types/portal'
import { makeSeanceInscription } from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import { existsIn } from '@/utils/arrays'
import store from '@/store'
import * as moment from 'moment/moment'

export default {
  name: 'InscriptionConfirmationsList',
  mixins: [BackendMixin],
  components: {
    XTable,
    FamilyTypeahead,
    CounterLabel,
    IndividualTypeahead,
    LoadingGif,
    PageHeader,
    SeancePeriodsSelector,
    YouthHomesMultipleSelector,
    YouthHomesPortalSelector,
    Pagination,
  },
  data() {
    return {
      loadingName: 'inscription-confirmations-list',
      limitIndicatorLoadingName: 'inscription-confirmations-limits',
      inscriptions: [],
      inscriptionsCount: 0,
      initialLoadingDone: false,
      limits: {},
      dateFilter: null,
      insDateFilter: null,
      seanceDateFilter: null,
      individualFilter: null,
      periodFilter: null,
      youthHomesFilter: [],
      loaded: false,
      periodInitialId: 0,
      individualInitialId: 0,
      familyInitialId: 0,
      familyFilter: null,
      initialYouthHomes: [],
      seanceLimits: new Map(),
      limitSelection: null,
      hasToBeRefused: false,
      services: [],
      page: 1,
      hasNext: false,
      hasPrevious: false,
      pageSize: 100,
      statusFilter: null,
      noPagination: false,
      detailDisplay: false,
      onlyPortal: true,
    }
  },
  watch: {
    statusChoices: function() {
      this.statusFilter = this.statusChoices[0]
    },
  },
  computed: {
    hasPreferences() {
      return this.items.filter(itm => itm.preference).length > 0
    },
    columns() {
      let columns = [
        {
          name: 'createdOn',
          label: 'Inscrit le',
          dateFormat: 'DD/MM/YYYY HH:mm',
        },
        {
          name: 'link1',
          label: '',
          hideFilter: true,
          onClick: (item) => {
            this.showIndividualSidebar(item.individual)
          },
          contentCallback: (col, item) => {
            return '<i class="fa fa-user"></i>'
          },
          maxWidth: '20px',
        },
        {
          name: 'fullName',
          label: 'Enfant',
          onClick: item => {
            this.onIndividualClicked(item.individual)
          },
        },
        {
          name: 'seance',
          label: 'Séance',
        },
        {
          name: 'status',
          label: 'Statut',
          contentCallback: (col, item) => {
            return '<span class="badge badge-' + item.status.badge + '">' +
              item.status.label + '</span>'
          },
        },
        {
          name: 'actionDate',
          label: 'le',
          dateFormat: 'DD/MM/YYYY HH:mm',
        }
      ]
      if (this.detailDisplay) {
        columns.push(
          {
            name: 'actionBy',
            label: 'par',
          }
        )
      }
      if (this.hasPreferences) {
        columns.push(
          {
            name: 'preference',
            label: 'Choix',
            contentCallback: (col, item) => {
              return '<span class="badge badge-info">' +
                item.preference + '</span>'
            },
          }
        )
      }
      columns.push(
        {
          name: 'ageGroup',
          label: 'Groupe',
        }
      )
      if (this.youthHomesFilter.length > 1) {
        columns.push(
          {
            name: 'youthHome',
            label: 'Accueil',
            contentCallback: (col, item) => {
              let name = item.inscription.seance.youthHome.name
              return '<span class="badge badge-dark">' + name + '</span>'
            },
          }
        )
      }
      if (this.periodFilter && this.periodFilter.id === 0) {
        columns.push(
          {
            name: 'period',
            label: 'Période',
            contentCallback: (col, item) => {
              let name1 = item.inscription.seance.seanceType.name
              let name2 = item.inscription.seance.period.name
              let badge1 = '<span class="badge badge-dark">' + name1 + '</span>'
              let badge2 = '<span class="badge badge-dark">' + name2 + '</span>'
              return badge1 + '<br />' + badge2
            },
          }
        )
      }
      if (this.detailDisplay) {
        columns.push(
          {
            name: 'paid',
            label: 'Paiement',
            contentCallback: (col, item) => {
              if (item.inscription.isPaid) {
                return '<span class="badge badge-success">payée</span>'
              } else {
                return '<span class="badge badge-danger">non payée</span>'
              }
            },
          }
        )
      }
      if (this.detailDisplay) {
        columns.push(
          {
            name: 'link2',
            label: '',
            hideFilter: true,
            onClick: (item) => {
              this.showEntitySidebar(item.entity)
            },
            contentCallback: (col, item) => {
              return '<i class="fa fa-people-roof"></i>'
            },
            maxWidth: '20px',
          }
        )
        columns.push(
          {
            name: 'entityName',
            label: 'Famille',
            onClick: item => {
              this.onFamilyClicked(item.inscription)
            },
          }
        )
      }
      return columns
    },
    items() {
      return this.displayedInscriptions.map(ins => this.makeItem(ins))
    },
    statusChoices() {
      const portalChoices = [
        makeChoice({ id: 0, name: 'Toutes du portail', }),
        makeChoice({ id: 1, name: 'Accepté du portail', }),
        makeChoice({ id: 2, name: 'Refusé du portail', })
      ]
      const nonPortalChoices = [
        makeChoice({ id: 4, name: 'Toutes les inscriptions', }),
        makeChoice({ id: 5, name: 'Accepté', }),
        makeChoice({ id: 6, name: 'Refusé', })
      ]
      let statusChoices = []
      if (this.onlyPortal) {
        // Seulement le portail
        statusChoices = portalChoices
      } else if (this.services.length) {
        // Portail et non
        statusChoices = portalChoices.concat(nonPortalChoices)
      } else {
        // pas de portail
        statusChoices = nonPortalChoices
      }
      return statusChoices
    },
    pagesCount() {
      if (this.noPagination) {
        return 1
      }
      return Math.ceil(this.inscriptionsCount / this.pageSize)
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    displayedInscriptions() {
      return this.inscriptions
    },
    youthHomesFilterAsText() {
      return this.youthHomesFilter.map(elt => elt.name).join(', ') || 'Aucun centre'
    },
    seances() {
      return this.inscriptions.reduce(
        (listOfSeances, currentInscription) => {
          if (!existsIn([currentInscription.seance.id], listOfSeances.map(elt => elt.id))) {
            listOfSeances.push(currentInscription.seance)
          }
          return listOfSeances
        },
        []
      )
    },
    noPaginationDisabled() {
      return (
        this.isLoading(this.loadingName) ||
        ((this.pagesCount === 1 && (this.inscriptionsCount.length <= 100))) ||
        (this.pagesCount > 10)
      )
    },
  },
  async mounted() {
    await this.loadServices()
    await this.onLoaded()
    if (this.statusChoices.length) {
      this.statusFilter = this.statusChoices[0]
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadServices() {
      const url = '/portal/api/youth-home-services/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.services = resp.data.map(elt => makePortalService(elt))
        if (this.services.length === 0) {
          this.onlyPortal = false
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    makeItem(inscription) {
      let actionBy = ''
      let status = {}
      let actionDate = inscription.createdOn
      actionBy = inscription.createdBy
      if (inscription.acceptedOn) {
        actionBy = inscription.acceptedBy
        actionDate = inscription.acceptedOn
      } else if (inscription.refusedOn) {
        actionBy = inscription.refusedBy
        actionDate = inscription.refusedOn
      }
      if (!inscription.refused && !inscription.waiting) {
        status = { badge: 'success', label: 'Acceptée', }
      } else if (inscription.refused && !inscription.waiting) {
        status = { badge: 'danger', label: 'Refusée', }
      } else if (inscription.refused && inscription.waiting) {
        status = { badge: 'warning', label: 'En attente', }
      }
      let preference = ''
      if (inscription.preference) {
        preference = 'N°' + inscription.preference
      }
      let youthHome = inscription.seance.youthHome.name
      let period = inscription.seance.seanceType.name + ' ' + inscription.seance.period.name
      return {
        id: inscription.id,
        createdOn: inscription.createdOn,
        individual: inscription.individual,
        fullName: inscription.individual.lastAndFirstName(),
        entity: inscription.entity,
        entityName: inscription.entity.name,
        seance: inscription.seance.name,
        ageGroup: inscription.ageGroup ? inscription.ageGroup.name : '',
        paid: 1,
        actionDate: actionDate,
        actionBy: actionBy,
        status: status,
        link1: 1,
        link2: 1,
        youthHome: youthHome,
        period: period,
        inscription: inscription,
        preference: preference,
      }
    },
    async onLoaded() {
      if (this.$route.query.date) {
        this.dateFilter = this.$route.query.date
      }
      if (this.$route.query.ins_date) {
        this.insDateFilter = this.$route.query.ins_date
      }
      if (this.$route.query.seance_date) {
        this.seanceDateFilter = this.$route.query.seance_date
      }
      if (this.$route.query.period) {
        this.periodInitialId = +this.$route.query.period
      }
      if (this.$route.query.individual) {
        this.individualInitialId = +this.$route.query.individual
        this.individualFilter = makeIndividual({ id: this.individualInitialId, })
      }
      if (this.$route.query.family) {
        this.familyInitialId = +this.$route.query.family
        this.familyFilter = makeFamily(
          { id: this.familyInitialId, entity: { id: this.familyInitialId, }, }
        )
      }
      if (this.$route.query.status) {
        const statusId = +this.$route.query.status
        const matching = this.statusChoices.filter(elt => elt.id === statusId)
        if (matching.length) {
          this.statusFilter = matching[0]
        } else {
          this.statusFilter = this.statusChoices[0]
        }
      }
      if (this.$route.query.youth_homes) {
        const queryYouthHomes = '' + this.$route.query.youth_homes
        this.initialYouthHomes = queryYouthHomes.split('-').map(elt => +elt)
      }
      if (this.$route.query.page) {
        this.page = (+this.$route.query.page) || 1
      }
      this.youthHomesFilter = this.initialYouthHomes.map(value => { return { id: value, } })
      const that = this
      setTimeout(
        async function() {
          await that.loadElements(that.page)
          // await that.loadLimits()
          that.loaded = true
        },
        500
      )
    },
    async onFilterChanged(page) {
      this.page = page
      if (this.loaded) {
        let queryArgs = { ...this.$route.query, }
        const periodId = this.periodFilter ? ('' + this.periodFilter.id) : ''
        const individualId = this.individualFilter ? ('' + this.individualFilter.id) : ''
        const familyId = this.familyFilter ? ('' + this.familyFilter.entity.id) : ''
        queryArgs.period = periodId
        queryArgs.youth_homes = this.youthHomesFilter.map(elt => ('' + elt.id)).join('-')
        queryArgs.individual = individualId
        queryArgs.family = familyId
        queryArgs.action_date = this.dateFilter
        queryArgs.ins_date = this.insDateFilter
        queryArgs.seance_date = this.seanceDateFilter
        if (this.statusFilter && this.statusFilter.id) {
          queryArgs.status = this.statusFilter.id
        }
        queryArgs.page = page
        await this.loadElements(page)
        try {
          await this.$router.replace({ query: queryArgs, })
        } catch {
        }
      }
    },
    async loadElements(page) {
      this.startLoading(this.loadingName)
      let url = '/api/youth/inscription-confirmations-list/?'
      if (this.dateFilter) { url += '&action_date=' + this.dateFilter }
      if (this.insDateFilter) { url += '&ins_date=' + this.insDateFilter }
      if (this.seanceDateFilter) { url += '&seance_date=' + this.seanceDateFilter }
      if (this.periodFilter) { url += '&period=' + this.periodFilter.id }
      let youthHomesStr = this.youthHomesFilter.map(elt => ('' + elt.id)).join('-')
      if (!youthHomesStr) {
        youthHomesStr = '0'
      }
      url += '&youth_homes=' + youthHomesStr
      if (this.individualFilter) { url += '&individual=' + this.individualFilter.id }
      if (this.familyFilter) { url += '&entity=' + this.familyFilter.entity.id }
      if (this.statusFilter && this.statusFilter.id) { url += '&status=' + this.statusFilter.id }
      url += ('&page=' + page)
      url += '&page_size=' + this.pageSize
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.initialLoadingDone = true
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.inscriptionsCount = resp.data.count
        this.inscriptions = resp.data.results.map(elt => makeSeanceInscription(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getSeanceInscriptionLimits(seance) {
      return this.seanceLimits.get(seance.id) || null
    },
    onActionDateChanged(actionDate) {
      this.dateFilter = actionDate
      this.onFilterChanged(1)
    },
    onInsDateChanged(insDate) {
      this.insDateFilter = insDate
      this.onFilterChanged(1)
    },
    onSeanceDateChanged(seanceDate) {
      this.seanceDateFilter = seanceDate
      this.onFilterChanged(1)
    },
    onPeriodChanged(period) {
      this.periodFilter = period
      this.onFilterChanged(1)
    },
    async loadInitial() {
      await this.loadElements(this.page)
      // await this.loadLimits()
    },
    onYouthHomesChanged(youthHomes, initial) {
      this.youthHomesFilter = youthHomes
      this.onFilterChanged(1)
      if (initial) {
        this.loadInitial()
      }
    },
    onIndividualChanged(individual) {
      this.individualFilter = individual
      this.onFilterChanged(1)
    },
    onStatusChanged() {
      this.onFilterChanged(1)
    },
    onFamilyChanged(family) {
      this.familyFilter = family
      this.onFilterChanged(1)
    },
    onIndividualClicked(individual) {
      this.individualInitialId = individual.id
      this.onIndividualChanged(individual)
    },
    onFamilyClicked(inscription) {
      this.familyInitialId = inscription.entity.id
      this.onFamilyChanged(inscription) // on triche en passant une inscription
    },
    getLinkToEntity(entityId) {
      return { name: 'families-detail', params: { entityId: '' + entityId, }, }
    },
    onPageChanged(event) {
      this.onFilterChanged(event.page)
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'inscriptions-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      if (this.detailDisplay) {
        docUrl += '?landscape=1'
      }
      const docSlug = 'inscriptions-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    reloadPagination(value) {
      this.noPagination = value
      if (this.noPagination) {
        this.page = 1
        this.pageSize = this.inscriptionsCount
      } else {
        this.page = 1
        this.pageSize = 100
      }
      this.onFilterChanged(1)
    },
    getLinks() {
      const active = !this.isLoading(this.loadingName)
      const excelActive = active && this.displayedInscriptions.length
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: !active ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: !excelActive ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
  },
}
</script>

<style scoped lang="less">
.label {
  background: #f7f7f7;
  border-radius: 4px;
  display: inline-block;
  padding: 3px;
  font-size: 0.8em;
}
.filter-bar {
  background: #f7f7f7;
  margin: 10px 0;
  padding: 10px;
  border: solid 1px #888;
  border-radius: 4px;
}
.filter-bar-title {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #888;
}
.badges-holder .badge {
  margin: 0 1px;
}
.checks-links a {
  font-size: 0.8em;
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
  color: #222 !important;
  border: solid #222 1px;
  padding: 2px;
}
a.small-link{
  font-size: 0.8em;
  display: inline-block;
  color: #222 !important;
  border: solid #222 1px;
  padding: 1px;
  vertical-align: top;
}
.bold {
  display: inline-block;
  color: #222 !important;
  font-weight: bold;
  margin-right: 5px;
}
.ins-summary {
  padding: 3px;
  border: solid 1px #222;
  border-radius: 8px;
  background: #eee;
}
.legend-header {
  padding-bottom: 2px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 5px;
}
.actions-holder label{
  white-space: nowrap;
}
.warning-message {
  border: solid 1px #444;
  background: #ffca22;
  padding: 5px;
}
.family-info {
  font-size: 12px
}
.filter-info {
  list-style: none;
  background: #ccc;
  padding: 5px 10px;
  font-size: 12px;
  margin: 0;
  li {
    margin: 0;
    padding: 0;
  }
}
</style>
